<template>
  <v-card
    :style="backStyle"
    :outlined="isOutlined"
  >
    <v-card-title
      :style="centerStyle"
      class="align-center py-3"
    >
      <v-avatar
        :color="`${color}25`"
        size="38"
        class="elevation-3"
      >
        <v-icon
          size="22"
          :color="color"
          class="rounded-0"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>
      <span class="font-weight-semibold text-2xl ml-3">{{ statistics }}</span>

      <v-spacer></v-spacer>

      <v-fab-transition>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="showBtnDots"
              x-small
              icon
              class="me-n3 mt-n1"
              v-on="on"
            >
              <v-icon>
                {{ mdiHelpCircleOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </v-fab-transition>
    </v-card-title>

    <v-card-text
      :style="centerStyle"
      class="text-no-wrap text--primary mt-1 pb-3"
    >
      <p
        class="font-weight-semibold text-sm mb-1"
        :style="'color: #363636;'"
      >
        {{ startTitle }}
      </p>

      <div
        :style="centerStyle"
        class="d-flex align-end flex-wrap"
      >
        <!-- <span class="font-weight-semibold text-2xl me-1 mb-2">{{ statistics }}</span> -->
        <span
          v-if="showChange"
          class="text-xs mr-2"
          :class="checkChange(change) ? 'success--text':'error--text'"
        > {{ change }}</span>
        <span class="text-xs text--secondary mb-0">{{ subtitle }}</span>
      </div>
      <!-- <p
        :style="centerStyle"
        class="text-xs text--secondary mb-0"
      >
        {{ subtitle }}
      </p> -->
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiHelpCircleOutline } from '@mdi/js'

export default {
  props: {
    startTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    opacity: { type: String, default: '0.7' },
    background: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    tooltip: { type: String, default: '' },
    statistics: { type: [String, Number], default: '' },
    change: { type: [String, Number], default: '' },
    showBtnDots: { type: Boolean, default: true },
    showChange: { type: Boolean, default: true },
    isOutlined: { type: Boolean, default: false },
    isCentered: { type: Boolean, default: false },
  },
  computed: {
    centerStyle() {
      return this.isCentered ? 'margin-left: auto; width: fit-content; margin-right: auto' : ''
    },
    backStyle() {
      return `
      background-color: ${this.background};
      border-block-end-color: ${this.color};
      border-block-end-style: solid;
      border-block-end-width: 2px;
    `
    },
  },
  setup() {
    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '-') {
        return false
      }

      return true
    }

    return {
      mdiHelpCircleOutline,
      checkChange,
    }
  },
}
</script>

<style lang="scss" scoped>
.percentage {
  top: -8px;
  position: relative;
}
</style>
